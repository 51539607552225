import { isChatLoading } from '@svelte/lib/store/common';

export function tidioScriptWithDelay(delayMs: number = 3500) {
  const isProductionOrStaging = ['production', 'staging'].includes(import.meta.env.VITE_APP_ENV);
  if (!isProductionOrStaging) {
    return;
  }

  if (getTidioScripElement()) {
    return;
  }

  document.addEventListener('DOMContentLoaded', () => {
    setTimeout(addTidioScript, delayMs);
  });

  document.addEventListener('scroll', addTidioScriptOnEvent);
  document.addEventListener('mousemove', addTidioScriptOnEvent);
  document.addEventListener('touchstart', addTidioScriptOnEvent);
}

export function overrideZIndex(zIndex: string = '3') {
  const tidioIframe = document.getElementById('tidio-chat-iframe');
  if (tidioIframe) {
    tidioIframe.style.zIndex = zIndex;
  }
}

function addTidioScriptOnEvent(event: Event) {
  addTidioScript();
  if (event.currentTarget) {
    event.currentTarget.removeEventListener(event.type, addTidioScriptOnEvent);
  }
}

export function addTidioScript() {
  if (getTidioScripElement()) {
    return;
  }

  const script = document.createElement('script');
  script.src = '//code.tidio.co/fxliz4txe8wsib16bt8n1xl3usdix5hs.js';
  script.async = true;
  document.head.appendChild(script);

  document.addEventListener('tidioChat-ready', () => {
    overrideZIndex();
    isChatLoading.set(false);
  });
}

export function getTidioScripElement() {
  return document.querySelector('script[src*="code.tidio.co"]');
}
